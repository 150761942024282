import React, { useEffect, useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import Logincarousel from "../Logincarousel/Logincarousel";
import images from "../../Assets/Assets";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../Redux/Slices/AuthSlices";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, token, error } = useSelector((state) => state.auth);

  // Extract activation key from URL and activate account
  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const activationKey = queryParams.get("activation_key");
  //   console.log("Extracted Activation Key:", activationKey); // Debug log
  //   if (activationKey) {
  //     activateAccount(activationKey);
  //   }
  // }, [location]);

  // Function to activate the account
  // const activateAccount = async (activationKey) => {
  //   try {
  //     const response = await axios.post(`${BASE_URL}/api/v1/activate-account`, {
  //       activation_key: activationKey,
  //     });

  //     if (response.data.success) {
  //       toast.success("Account activated successfully! Please log in.");
  //     } else {
  //       toast.error("Activation failed. Please try again.");
  //     }
  //   } catch (error) {
  //     toast.error("Invalid activation key or activation failed.");
  //   }
  // };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission (Login)
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!credentials.email || !credentials.password) {
      toast.error("Please enter both email and password.");
      return;
    }

    try {
      const response = await dispatch(loginUser(credentials)).unwrap();
      // console.log("Login Response:", response);

      if (response?.data?.token) {
        sessionStorage.setItem("token", response.data.token);

        toast.success("logged in successfully!");

        setTimeout(() => {
          navigate("/home");
        }, 1000);
      } else {
        toast.error(response.message || "Invalid credentials. Try again.");
      }
    } catch (error) {
      console.error("Login Error:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="lg:flex lg:justify-between">
        {/* Left Section */}
        <section className="hidden lg:block lg:w-[843px]">
          <div
            className="w-full h-[100vh] bg-cover bg-center text-white lg:flex flex-col justify-center items-center relative"
            style={{ backgroundImage: `url(${images.background})` }}
          >
            <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
            <div className="absolute top-10 left-10 z-20">
              <img src={images.logo} alt="logo" />
            </div>
            <div className="font-semibold w-[563px] text-center z-20 mt-20">
              <div className="text-[58px] leading-[56px] text-white">
                Manage your Money Anywhere
              </div>
              <div className="text-[#FFFFFF] text-[16px] text-center leading-[24px] mt-5">
                Investing is not just a financial decision, it's a commitment to
                your future prosperity.
              </div>
              <div className="mt-12 w-full overflow-visible">
                <Logincarousel />
              </div>
            </div>
          </div>
        </section>

        {/* Right Section */}
        <section className="w-full lg:w-1/2 flex justify-center items-center">
          <div className="w-[90%] sm:w-[397px] flex flex-col gap-y-5 mx-auto">
            <div className="md:hidden pt-5">
              <img src={images.logo} alt="logo" />
            </div>
            <div>
              <div className="font-semibold dark:text-white lg:text-[34px] text-[#2B2A2A] lg:text-center text-left text-[24px]">
                Welcome to Propmodel
              </div>
              <div className="text-[#6C6C6C] dark:text-[#A4A4A4] text-[14px] font-normal lg:text-center text-left mt-2">
                Please sign-in to your account to start trading
              </div>
            </div>

            {/* Login Form */}
            <form onSubmit={handleSubmit} className="flex flex-col gap-y-5">
              <input
                className="h-[56px] w-full dark:border-[#424346] dark:bg-[#121212] rounded-lg border border-[#D0D5DD] pl-5 outline-none placeholder-style"
                type="email"
                placeholder="Email"
                name="email"
                value={credentials.email}
                onChange={handleInputChange}
              />
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="h-[56px] w-full rounded-lg border dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] pl-5 pr-12 outline-none placeholder-style"
                  placeholder="Password"
                  name="password"
                  value={credentials.password}
                  onChange={handleInputChange}
                />
                <div
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="text-gray-500" />
                  ) : (
                    <Eye className="text-gray-500" />
                  )}
                </div>
              </div>

              {/* Forgot Password */}
              <div className="flex justify-between text-[16px]">
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  Remember Me
                </div>
                <Link
                  to="/forget-password"
                  className="text-[#6699FF] cursor-pointer"
                >
                  Forgot Password?
                </Link>
              </div>

              {/* Login Button */}
              <div>
                <button
                  type="submit"
                  className="h-[44px] w-full rounded-lg bg-[#6699FF] text-white font-semibold text-[16px]"
                >
                  {loading ? "Logging in..." : "Login"}
                </button>
              </div>

              {/* Signup Link */}
              <div className="text-[#6C6C6C] text-center">
                New to our platform?{" "}
                <Link to={"/signup"}>
                  <span className="text-[#6699FF] font-semibold cursor-pointer">
                    Create an account
                  </span>
                </Link>
              </div>
            </form>

            <div className="flex items-center gap-4">
              <hr className="flex-grow border-childBorder" />
              <span className="text-gray-500">or</span>
              <hr className="flex-grow border-childBorder" />
            </div>
            <div className="flex gap-x-3 justify-center">
              {[
                images.facebook,
                images.google,
                images.linkdin,
                images.xsocialmedia,
              ].map((icon, index) => (
                <div
                  key={index}
                  className="border-[#D0D5DD] dark:border-[#424346] border lg:w-[59px] lg:h-[59px] w-[40px] h-[40px] rounded-full flex justify-center items-center cursor-pointer"
                >
                  <img
                    src={icon}
                    alt="social media"
                    className="lg:w-[36px] lg:h-[36px] w-[25px] h-[25px]"
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
