import { TriangleAlert } from "lucide-react";
import React, { useRef, useEffect, useState } from "react";

const RequestPayoutPopup = ({ isOpen, onClose }) => {
  const popupRef = useRef(null);

  const [activeTab, setActiveTab] = useState("WISE");

  const tabs = ["WISE", "REVOLUT", "PAYPAL", "CRYPTO", "BANK TRANSFER"];

  // Close the popup when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Clean up when the component is unmounted
    };
  }, [isOpen]);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex lg:justify-center lg:items-start pt-10 justify-end items-end z-50">
      <div
        ref={popupRef}
        className="bg-white dark:bg-[#121212] lg:rounded-2xl rounded-t-3xl p-6 lg:w-[50%] max-w-[1100px] flex flex-col justify-between shadow-lg"
      >
        {/* Header */}
        <div className="flex justify-between items-center ">
          <h2 className="font-semibold lg:text-[34px] text-[20px] text-gray-800 dark:text-white text-center w-full">
            Request a Payout
          </h2>
          <button
            onClick={onClose}
            className="text-white w-[24px] h-[24px] rounded-full bg-[#9E9FAB] transition duration-200"
          >
            ✕
          </button>
        </div>

        {/* Description */}
        <p className="text-[#6C6C6C] lg:text-[16px] text-center font-medium mb-6">
          Select your preferred payment method below and enter your account
          details.
        </p>
        <div className="text-[#FDB528] dark:bg-[#2D2600] lg:text-[14px] text-[12px]  bg-[#f9f2e5] gap-x-1 mb-6 flex justify-center items-center py-3 px-2 w-full rounded-lg font-normal">
          <TriangleAlert /> <span className="font-bold">Note:</span> Close all
          open trades and pending orders before requesting a payout.Learn More
        </div>
        <hr className="border-childBorder" />

        <div className="flex justify-between lg:items-center lg:flex-row flex-col my-4 text-[#6C6C6C] font-semibold">
          <div>Profit:</div>
          <div>Consistency Score:</div>
          <div>Payout Amount:</div>
        </div>
        <div className="mb-4 ">
          {/* Tabs for larger screens */}
          <div className="hidden sm:flex bg-[#F5F5F5] dark:bg-[#0D0D0D] w-full h-[42px] rounded-xl items-center justify-center px-2">
            {tabs.map((tab) => (
              <div
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`w-full h-[30px] text-[13px] font-semibold rounded-lg flex justify-center items-center cursor-pointer transition-all duration-200 ${
                  activeTab === tab
                    ? "text-[#6699FF] bg-white dark:bg-[#011845] dark:text-white "
                    : "text-[#6C6C6C]"
                }`}
              >
                {tab}
              </div>
            ))}
          </div>

          {/* Dropdown for mobile devices */}
          <div className="sm:hidden">
            <select
              value={activeTab}
              onChange={(e) => setActiveTab(e.target.value)}
              className="w-full h-[42px] outline-none dark:bg-[#121212] dark:border-[#252525] bg-[#F5F5F5] border border-[#EAEAEC] text-[#6699FF] text-[13px] font-semibold rounded-xl px-3"
            >
              {tabs.map((tab) => (
                <option key={tab} value={tab}>
                  {tab}
                </option>
              ))}
            </select>
          </div>
        </div>

        <form>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
            <input
              className="w-full h-[56px] dark:border-[#424346] dark:bg-[#121212] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
              name="firstName"
              placeholder="Joshua Casey"
            />
            <input
              className="w-full h-[56px] dark:border-[#424346] dark:bg-[#121212] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
              name="accountNumber"
              placeholder="#78352"
            />
            <input
              className="w-full h-[56px] dark:border-[#424346] dark:bg-[#121212] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
              name="phone"
              placeholder="+61 493 166 747"
            />
            <input
              className="w-full h-[56px] dark:border-[#424346] dark:bg-[#121212] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
              name="amount"
              placeholder="$1,750.00"
            />
          </div>

          <hr className="border-childBorder my-5" />
          <div className="flex justify-end gap-x-5">
            <button
              onClick={onClose}
              className=" text-[#6699FF] dark:border-[#424346] dark:bg-[#1C1C1C] border border-[#EAEAEC] bg-[#FAFCFD] w-[94px] h-[38px] rounded-lg "
            >
              Cancel
            </button>
            <button className=" bg-[#6699FF] text-white w-[94px] h-[38px] rounded-lg ">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestPayoutPopup;
