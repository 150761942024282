import React, { useEffect, useRef, useState } from "react";
import search from "../../Assets/Navbar/search.svg";
import arrowdown from "../../Assets/Navbar/arrow-down.svg";
import logo from "../../Assets/Sidebar/Logo.svg";
import { User, HelpCircle, LogOut } from "lucide-react";
import {
  Bell,
  ChevronDown,
  ChevronUp,
  CircleX,
  Menu,
  Moon,
  SunMedium,
} from "lucide-react";
import {
  Account,
  Affiliate,
  Competition,
  Education,
  Home,
  Leaderboard,
  Mastery,
  Star,
  Support,
  Tools,
} from "../../Assets/Sidebar/SvgComponents";
import { Link, useNavigate } from "react-router-dom";
import { MessageSvg, NotificationSvg } from "../../Assets/Navbar/SvgNavbar";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeProvider";
import { getUserProfile, logoutUser } from "../../Redux/Slices/AuthSlices";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.auth);

  // Fetch user data
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const navigate = useNavigate();
  const { storeValue, setStoreValue } = useContext(ThemeContext);
  // console.log(storeValue);
  useEffect(() => {
    if (storeValue === "dark") {
      document.documentElement.setAttribute("data-theme", storeValue);
    } else {
      document.documentElement.setAttribute("data-theme", storeValue);
    }
  }, [storeValue]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Clean up when the component is unmounted
    };
  }, [isMenuOpen]);
  const toggleSubmenu = (index) => {
    setOpenMenu(openMenu === index ? null : index);
  };

  const Menus = [
    { icon: <Home />, title: "Home", path: "/home" },
    {
      heading: "Challenges & Achievements",
      icon: <Star />,
      title: "Challenge",
      submenu: true,
      submenuItems: [
        { title: "New Challenges", path: "/challenge/new-challenge" },
        { title: "My Challenge", path: "/challenge/my-challenge" },
      ],
      // path: "/challenge",
    },
    {
      icon: <Competition />,
      title: "Competitions",
      path: "/competitions",
    },
    {
      icon: <Leaderboard />,
      title: "Leaderboard",
      path: "/leaderboard",
    },
    {
      heading: "Tools & Education",
      icon: <Tools />,
      title: "Tools",
      submenu: true,
      submenuItems: [
        { title: "Advanced Chart", path: "/tools/advanced-chart" },
        { title: "Economic Calender", path: "/tools/economic-calendar" },
        { title: "Calculators" },
        { title: "Downloads", path: "/tools/downloads" },
      ],
      // path: "/tools",
    },
    {
      icon: <Education />,
      title: "Education",
      submenu: true,
      submenuItems: [
        { title: "Trading Courses", path: "/education/trading-courses" },
        {
          title: "Propmodel Tutorials",
          path: "/education/propmodel-tutorials",
        },
      ],
      // path: "/education",
    },
    {
      icon: <Mastery />,
      title: "Mastery",
      submenu: true,
      submenuItems: [
        { title: "Achievements", path: "/mastery/achievements" },
        { title: "Certificates", path: "/mastery/certificates" },
      ],
      // path: "/mastery",
    },
    {
      heading: "My Account",
      icon: <Affiliate />,
      title: "Affiliate",
      path: "/affiliate",
    },
    {
      icon: <Account />,
      title: "Account",
      submenu: true,
      submenuItems: [
        { title: "Profile", path: "/account/profile" },
        { title: "Security", path: "/account/security" },
        { title: "Billing", path: "/account/billing" },
        { title: "Certificates", path: "" },
      ],
      // path: "/account",
    },
    {
      icon: <Support />,
      title: "Support",
    },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle Logout
  const handleLogout = () => {
    dispatch(logoutUser());
    setIsOpen(false);
    navigate("/login");
  };
  return (
    <div className="flex justify-between items-center w-full p-4 border-b border-childBorder ">
      <div className="flex items-center justify-between w-full lg:hidden">
        {/* Logo */}
        <Link to={"/home"}>
          <div className="flex items-center lg:hidden">
            <img src={logo} alt="Logo" className="w-[32px] h-[32px]" />
          </div>{" "}
        </Link>

        <div className="flex sm:hidden gap-x-2 items-center">
          <div className="p-2 bg-[#FAFCFD] dark:bg-[#1C1C1C] rounded-full">
            <Bell color="#6C6C6C" />
          </div>
          <button
            className="p-2 bg-[#FAFCFD] dark:bg-[#1C1C1C] rounded-full"
            onClick={toggleMenu}
          >
            <Menu color="#6C6C6C" />
          </button>
        </div>
      </div>

      <div className="hidden sm:flex items-center w-full justify-between">
        <div className="flex items-center p-2 rounded-md w-full max-w-md">
          <img src={search} alt="icon" className="w-5 h-5 mr-2" />
          <input
            type="text"
            placeholder="What are you looking for...?"
            className="w-full bg-transparent outline-none placeholder-gray-600 text-sm"
          />
        </div>

        <div className="flex items-center gap-x-4">
          <div className="bg-iconBackground w-[36px] h-[36px] rounded-full flex justify-center items-center">
            <MessageSvg />
          </div>
          <div className="bg-iconBackground w-[36px] h-[36px] rounded-full flex justify-center items-center">
            <NotificationSvg />
          </div>

          <div className="relative" ref={dropdownRef}>
            {/* Avatar Section */}
            <div
              className="flex items-center cursor-pointer"
              onClick={toggleDropdown}
            >
              <div className="flex items-center lg:border-r lg:border-l border-childBorder px-3 py-1">
                <div className="relative w-10 h-10 cursor-pointer">
                  <div className="w-10 h-10  rounded-full bg-gradient-to-br from-purple-400 to-blue-500 flex items-center justify-center text-white text-lg font-semibold">
                    {user?.first_name?.[0]?.toUpperCase() || ""}
                  </div>
                  <span className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 border-2 border-white rounded-full"></span>
                </div>

                <div className="hidden sm:flex flex-col ml-2">
                  <span className="font-semibold text-[12px] sm:text-[14px]">
                    {user?.first_name || ""} {user?.last_name || ""}
                  </span>
                  <span className="text-[#6C6C6C] text-[12px] sm:text-[14px]">
                    {user?.email ? user.email.split("@")[0] + "@" : ""}
                  </span>
                </div>
              </div>
            </div>

            {/* Dropdown Menu */}
            {isOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                <div className="p-3 border-b">
                  <div className="flex items-center space-x-2">
                    <div className="w-10 h-10 rounded-full bg-gradient-to-br from-purple-500 to-blue-500 flex items-center justify-center text-white text-lg font-semibold">
                      {user?.first_name[0].toUpperCase()}
                    </div>
                    <div>
                      <p className="text-sm font-semibold text-gray-700">
                        {user?.first_name}
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="py-2">
                  <Link to={"/account/profile"}>
                    <li className="px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 cursor-pointer">
                      <User className="w-4 h-4 text-gray-600" />
                      <span>Account</span>
                    </li>
                  </Link>
                  <li className="px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 cursor-pointer">
                    <HelpCircle className="w-4 h-4 text-gray-600" />
                    <span>Support</span>
                  </li>
                  <li
                    onClick={handleLogout}
                    className="px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 cursor-pointer text-red-600"
                  >
                    <LogOut className="w-4 h-4" />
                    <span>Log Out</span>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div className="hidden lg:flex items-center cursor-pointer">
            EN
            <img src={arrowdown} alt="Dropdown Arrow" className="ml-1" />
          </div>
        </div>
      </div>

      {/* Sliding Menu */}

      <div
        className={`fixed top-0 left-0 h-full w-64 bg-white dark:bg-[#121212] shadow-lg transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <div className="flex justify-between items-center p-4 ">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="w-[32px]" />
            <div className="dark:text-white font-semibold">Propmodel</div>
          </div>{" "}
          <button
            onClick={toggleMenu}
            className="bg-[#FAFCFD] dark:bg-[#121212] w-[32px] h-[32px] rounded-full flex justify-center items-center"
          >
            <CircleX size={24} color="#6C6C6C" />
          </button>
        </div>

        <div className="p-4">
          <ul className="space-y-4">
            {Menus.map((menu, index) => (
              <li key={index}>
                {/* Heading */}
                {menu.heading && (
                  <div className="text-[#C6C6C6] text-sm  mb-2">
                    {menu.heading}
                  </div>
                )}

                {/* Main Menu Item */}
                <Link
                  to={menu.path}
                  onClick={() => {
                    if (menu.path) setIsMenuOpen(false);
                  }}
                >
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => toggleSubmenu(index)}
                  >
                    {menu.icon && <span>{menu.icon}</span>}
                    <span className="text-[#6C6C6C] hover:text-blue-500 flex-1">
                      {menu.title}
                    </span>

                    {/* Submenu Indicator */}
                    {menu.submenu && (
                      <span className="text-[#6C6C6C]">
                        {openMenu === index ? <ChevronUp /> : <ChevronDown />}
                      </span>
                    )}
                  </div>
                </Link>

                {/* Submenu Items */}
                {menu.submenu && menu.submenuItems && openMenu === index && (
                  <ul className="ml-6 mt-2 space-y-2">
                    {menu.submenuItems.map((submenu, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          onClick={() => {
                            if (submenu.path) setIsMenuOpen(false);
                          }}
                          to={submenu.path}
                          className="text-[#6C6C6C] hover:text-blue-400"
                        >
                          {submenu.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div>
          <div className="pt-5 flex justify-center">
            <div
              className={`bg-[#EEEEEE] dark:bg-[#0D0D0D] rounded ${
                open ? "w-[240px]" : "w-[80px] flex-col"
              } flex p-2 duration-300`}
            >
              {open ? (
                <>
                  <button
                    onClick={() => setStoreValue("dark")}
                    className={`${
                      open ? "w-[114px]" : "w-[50px]"
                    } h-[32px] rounded font-semibold ${
                      open
                        ? " dark:bg-[#011845] text-black dark:text-white"
                        : "bg-transparent text-[#6C6C6C]"
                    } flex justify-center items-center gap-2 duration-300`}
                  >
                    <SunMedium />
                    {open && "Dark"}
                  </button>
                  <button
                    onClick={() => setStoreValue("light")}
                    className={`${
                      open ? "w-[114px]" : "w-[50px]"
                    } h-[32px] rounded font-semibold ${
                      open
                        ? "bg-[#FFFFFF] text-black dark:bg-[#0D0D0D] dark:text-white"
                        : "bg-transparent text-[#6C6C6C]"
                    } flex justify-center items-center gap-2 duration-300`}
                  >
                    <Moon />
                    {open && "Light"}
                  </button>
                </>
              ) : (
                <button
                  onClick={() =>
                    setStoreValue(storeValue === "light" ? "dark" : "light")
                  }
                  className={`w-[50px] h-[32px] rounded font-semibold ${
                    storeValue === "light"
                      ? "bg-[#FFFFFF] text-black "
                      : "bg-[#011845] text-white "
                  } flex justify-center items-center duration-300`}
                >
                  {storeValue === "light" ? <SunMedium /> : <Moon />}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={toggleMenu}
        ></div>
      )}
    </div>
  );
};

export default Navbar;
