import { Eye, EyeOff } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPassword } from "../../../../Redux/Slices/AuthSlices";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleToggle = (field) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.new_password !== formData.confirm_password) {
      toast.error("New password and confirm password do not match!", {
        position: "top-right",
      });
      return;
    }

    dispatch(changeUserPassword(formData));
  };

  // Handle toast notifications and form reset
  useEffect(() => {
    if (success) {
      toast.success("Password updated successfully!", {
        position: "top-right",
      });

      // Reset form fields
      setFormData({
        current_password: "",
        new_password: "",
        confirm_password: "",
      });
    }

    if (error) {
      toast.error(error, { position: "top-right" });

      // Reset only the current password field in case of error
      setFormData((prev) => ({
        ...prev,
        current_password: "",
      }));
    }
  }, [success, error]);

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />

      <div>
        <div className="font-semibold text-[20px]">Change Password</div>
        <form onSubmit={handleSubmit} className="mt-2 flex flex-col gap-4">
          {/* Current Password */}
          <div className="relative">
            <input
              type={showPassword.current ? "text" : "password"}
              name="current_password"
              value={formData.current_password}
              onChange={handleChange}
              className="h-[56px] w-full rounded-xl dark:bg-[#121212] dark:border-[#424346] border pl-5 pr-12 outline-none placeholder-style"
              placeholder="Current Password"
              required
            />
            <div
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={() => handleToggle("current")}
            >
              {showPassword.current ? (
                <EyeOff className="text-gray-500" />
              ) : (
                <Eye className="text-gray-500" />
              )}
            </div>
          </div>

          {/* New Password */}
          <div className="relative">
            <input
              type={showPassword.new ? "text" : "password"}
              name="new_password"
              value={formData.new_password}
              onChange={handleChange}
              className="h-[56px] w-full rounded-xl dark:bg-[#121212] dark:border-[#424346] border pl-5 pr-12 outline-none placeholder-style"
              placeholder="New Password"
              required
            />
            <div
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={() => handleToggle("new")}
            >
              {showPassword.new ? (
                <EyeOff className="text-gray-500" />
              ) : (
                <Eye className="text-gray-500" />
              )}
            </div>
          </div>

          {/* Confirm New Password */}
          <div className="relative">
            <input
              type={showPassword.confirm ? "text" : "password"}
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              className="h-[56px] w-full rounded-xl dark:bg-[#121212] dark:border-[#424346] border pl-5 pr-12 outline-none placeholder-style"
              placeholder="Confirm New Password"
              required
            />
            <div
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={() => handleToggle("confirm")}
            >
              {showPassword.confirm ? (
                <EyeOff className="text-gray-500" />
              ) : (
                <Eye className="text-gray-500" />
              )}
            </div>
          </div>

          {/* Password Requirements */}
          <div className="mt-5">
            <div className="font-semibold">Password Requirements:</div>
            <ul className="text-[#6C6C6C] list-disc pl-4 leading-7">
              <li>Minimum 8 characters long - the more, the better</li>
              <li>At least one lowercase character</li>
              <li>At least one number, symbol, or whitespace character</li>
            </ul>
          </div>

          <hr className="my-5 dark:border-[#424346]" />

          {/* Buttons */}
          <div className="flex justify-end gap-3">
            <button
              type="button"
              className="bg-[#FAFCFD] dark:bg-[#121212] dark:border-[#424346] border-[#EAEAEC] border w-full lg:w-[122px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
              onClick={() =>
                setFormData({
                  current_password: "",
                  new_password: "",
                  confirm_password: "",
                })
              }
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#6699FF] w-full lg:w-[146px] h-[38px] rounded-lg text-white font-semibold"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Changes"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
