import { Copy, TriangleAlert } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountCredentials } from "../../../Redux/Slices/NewChallengeSlices";
import {
  Box,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

const CredentialsPopup = ({ isOpen, onClose, mt5_login_id }) => {
  const popupRef = useRef(null);
  const dispatch = useDispatch();
  const { accountCredentials } = useSelector((state) => state.newChallenge);
  const [copiedField, setCopiedField] = useState(null);

  useEffect(() => {
    if (mt5_login_id) {
      dispatch(getAccountCredentials(mt5_login_id));
    }
  }, [dispatch, mt5_login_id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleCopy = (text, field) => {
    if (text) {
      navigator.clipboard.writeText(text);
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 1500);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex lg:justify-center lg:items-start pt-10 justify-end items-end z-50">
      <div
        ref={popupRef}
        className="bg-white dark:bg-[#121212] lg:rounded-2xl rounded-t-3xl p-6 lg:w-[60%] max-w-[1100px] flex flex-col justify-between shadow-lg"
      >
        <div className="flex justify-between items-center ">
          <h2 className="font-semibold dark:text-white lg:text-[34px] text-[20px] text-gray-800 text-center w-full">
            Account Credentials
          </h2>
          <button
            onClick={onClose}
            className="text-white w-[24px] h-[24px] rounded-full bg-[#9E9FAB] transition duration-200"
          >
            ✕
          </button>
        </div>

        <p className="text-[#6C6C6C] lg:text-[16px] text-center font-medium mb-6">
          Below are your MT5 trading account credentials. Login to start
          trading.
        </p>

        <div className="text-[#FDB528] dark:bg-[#2D2600] lg:text-[14px] text-[12px] bg-[#f9f2e5] gap-x-1 mb-6 flex justify-center items-center py-3 px-2 w-full rounded-lg font-normal">
          <TriangleAlert /> <span className="font-bold">Note:</span> Download
          our MT5 installer below to get started. Learn More
        </div>
        <hr className="border-childBorder" />

        <form className="mt-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
            {[
              {
                label: "Account Number",
                value: accountCredentials?.data?.account_number,
              },
              {
                label: "Password",
                value: accountCredentials?.data?.account_password,
              },
              {
                label: "Account Currency",
                value: accountCredentials?.data?.currency,
              },
              { label: "Account Type", value: accountCredentials?.data?.type },
              {
                label: "Server Details",
                value: accountCredentials?.data?.server_detail,
              },
            ].map((field, index) => (
              <div key={index} className="relative w-full">
                <TextField
                  className="custom-textfield"
                  fullWidth
                  variant="outlined"
                  label={field.label}
                  value={field.value || ""}
                  InputProps={{
                    readOnly: true,
                    endAdornment:
                      field.label !== "Account Currency" &&
                      field.label !== "Account Type" ? (
                        <Tooltip
                          title={
                            copiedField === field.label ? "Copied" : "Copy"
                          }
                          arrow
                          open={copiedField === field.label}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <IconButton
                            onClick={() => handleCopy(field.value, field.label)}
                          >
                            <Copy style={{ color: "#9E9FAB" }} />
                          </IconButton>
                        </Tooltip>
                      ) : null,
                  }}
                />
              </div>
            ))}
            {/* MT5 Installer Field */}
            <div className="relative w-full">
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{
                  position: "absolute",
                  top: -10,
                  left: 10,
                  background: "#fff",
                  px: 1,
                }}
              >
                MT5 Installer
              </Typography>

              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "12px",
                  padding: "12px",
                  minHeight: "56px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography>
                  {Object.keys(accountCredentials?.data?.mt5_installer || {})
                    .map((key) => (
                      <Link
                        key={key}
                        href={accountCredentials?.data?.mt5_installer[key]}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="hover"
                        color="inherit"
                      >
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </Link>
                    ))
                    .reduce(
                      (prev, curr) =>
                        prev.length ? [prev, " / ", curr] : [curr],
                      []
                    )}
                </Typography>
              </Box>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CredentialsPopup;
