import React, { useEffect, useState } from "react";
import {
  AccountView,
  MedalStar,
} from "../../../Assets/Mychallenge/SvgMyChallenge";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMyChallenge } from "../../../Redux/Slices/NewChallengeSlices";
import Loader from "../../../Components/Loader/Loader";
const BlueProgressBar = ({ progress }) => {
  return (
    <div className="w-full  bg-[#EDF4FF] dark:bg-[#011845] rounded-full h-2 mt-2">
      <div
        className="bg-[#6699FF] h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
const MyChallenge = () => {
  const [activeTab, setActiveTab] = useState("myChallenge");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "newChallenge") {
      navigate("/challenge/new-challenge");
    } else if (tab === "myChallenge") {
      navigate("/challenge/my-challenge");
    }
  };
  const { getMyChallengeData, loading, error } = useSelector(
    (state) => state.newChallenge
  );

  useEffect(() => {
    dispatch(getMyChallenge());
  }, [dispatch]);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (error) return <p className="text-red-500">Error: {error}</p>;
  return (
    <div className="bg-background border border-childBorder rounded-2xl p-5 text-textPrimary">
      <div className="flex justify-center mt-5">
        <div className="bg-[#F5F5F5] dark:bg-[#0D0D0D] w-[256px] h-[42px] rounded-xl flex items-center justify-center px-2">
          <div
            onClick={() => handleTabClick("newChallenge")}
            className={`w-[127px] h-[30px] text-[13px] flex justify-center items-center cursor-pointer ${
              activeTab === "newChallenge"
                ? "bg-[white] text-[#6699FF] font-semibold rounded-lg"
                : "text-[#6C6C6C]"
            }`}
          >
            New Challenge
          </div>
          <div
            onClick={() => handleTabClick("myChallenge")}
            className={`w-[127px] h-[30px] text-[13px] flex justify-center items-center cursor-pointer ${
              activeTab === "myChallenge"
                ? "bg-[white] text-[#6699FF] dark:text-white dark:bg-[#011845] font-semibold rounded-lg"
                : "text-[#6C6C6C]"
            }`}
          >
            My Challenge
          </div>
        </div>
      </div>

      <div className="font-semibold text-center text-[24px] lg:text-[34px] mt-5">
        Active Challenges
      </div>
      <div className="text-[#6C6C6C] text-center text-[14px] lg:text-[16px]">
        Access your dashboards and account credentials below.
      </div>
      {/*This is for large screen*/}
      <div className="grid gap-5 mt-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
        {getMyChallengeData &&
          getMyChallengeData?.data?.items?.map((challenge, uuid) => {
            let statusComponent;

            if (challenge.status === "pending") {
              statusComponent = (
                <div className="border bg-[#f9f3e6] dark:bg-[#2D2600] border-[#FDB528] rounded-2xl p-5 flex flex-col gap-y-5 w-full">
                  <div className="flex items-center gap-x-2">
                    <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                      <MedalStar />
                    </div>
                    <div>
                      <div className="text-[16px] font-semibold">
                        Account #{challenge.mt5_login_id}
                      </div>
                      <div className="text-[#6C6C6C] font-medium text-[14px]">
                        @Account#{challenge.mt5_login_id}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <div className="flex justify-between">
                      <div className="border-[#FDB528] uppercase dark:bg-[#2D2600] border rounded-2xl text-[#FDB528] font-semibold text-[16px] flex justify-center items-center bg-[#f9f3e6] w-[92px]">
                        {challenge.status}
                      </div>
                      <div className="text-[20px] font-semibold">
                        ${challenge.amount_total}k
                      </div>
                    </div>
                    <hr className="border border-childBorder" />
                    <div className="flex justify-between">
                      <div>
                        <div className="text-[#6C6C6C] text-[12px]">
                          Evaluation
                        </div>
                        <div className="font-semibold text-[16px]">
                          {" "}
                          {String(challenge.account_stage).charAt(0).toUpperCase() +
                            String(challenge.account_stage).slice(1)}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="text-[#6C6C6C] text-[12px]">
                          Current Stage:
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.current_stage).charAt(0).toUpperCase() +
                            String(challenge.current_stage).slice(1)}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-[#6C6C6C] text-[12px]">
                          Account
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.account_type).charAt(0).toUpperCase() +
                            String(challenge.account_type).slice(1)}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="text-[#6C6C6C] text-[12px]">
                          Total Profit
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.total_profit).charAt(0).toUpperCase() +
                            String(challenge.total_profit).slice(1)}
                        </div>
                      </div>
                    </div>
                    <hr className="border border-childBorder" />
                    <div>
                      <BlueProgressBar progress={30} />
                      <div className="flex justify-between">
                        <div className="text-[#6C6C6C] font-medium">
                          Progress
                        </div>
                        <div>0%</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-x-2">
                    <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                      <AccountView />
                    </div>
                    <button
                      onClick={() =>
                        navigate("/challenge/dashboard", {
                          state: { mt5_login_id: challenge.mt5_login_id },
                        })
                      }
                      className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]"
                    >
                      Dashboard
                    </button>
                  </div>
                </div>
              );
            } else if (challenge.status === "failed") {
              statusComponent = (
                <div className="border bg-[#f3dedd] dark:bg-[#220000] border-[#FF4D49] rounded-2xl p-5 flex flex-col gap-y-5 w-full">
                  <div className="flex items-center gap-x-2">
                    <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                      <MedalStar />
                    </div>
                    <div>
                      <div className="text-[16px] font-semibold">
                        Account #{challenge.mt5_login_id}
                      </div>
                      <div className="text-[#6C6C6C] font-medium text-[14px]">
                        @Account#{challenge.mt5_login_id}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <div className="flex justify-between">
                      <div className="border-[#FF4D49] uppercase dark:bg-[#2D2600] border rounded-2xl text-[#FF4D49] font-semibold text-[16px] flex justify-center items-center bg-[#FFEAE9] w-[92px]">
                        {challenge.status}
                      </div>
                      <div className="text-[20px] font-semibold">
                        {" "}
                        ${challenge.amount_total}k
                      </div>
                    </div>
                    <hr className="border border-childBorder" />
                    <div className="flex justify-between">
                      <div>
                        <div className="text-[#6C6C6C] text-[12px]">
                          Evaluation
                        </div>
                        <div className="font-semibold text-[16px]">
                          {" "}
                          {String(challenge.account_stage).charAt(0).toUpperCase() +
                            String(challenge.account_stage).slice(1)}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="text-[#6C6C6C] text-[12px]">
                          Current Stage:
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.current_stage).charAt(0).toUpperCase() +
                            String(challenge.current_stage).slice(1)}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-[#6C6C6C] text-[12px]">
                          Account
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.account_type).charAt(0).toUpperCase() +
                            String(challenge.account_type).slice(1)}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="text-[#6C6C6C] text-[12px]">
                          Total Profit
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.total_profit).charAt(0).toUpperCase() +
                            String(challenge.total_profit).slice(1)}
                        </div>
                      </div>
                    </div>
                    <hr className="border border-childBorder" />
                    <div>
                      <BlueProgressBar progress={40} />
                      <div className="flex justify-between">
                        <div className="text-[#6C6C6C] font-medium">
                          Progress
                        </div>
                        <div>0%</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-x-2">
                    <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                      <AccountView />
                    </div>
                    <button
                      onClick={() =>
                        navigate("/challenge/dashboard", {
                          state: { mt5_login_id: challenge.mt5_login_id },
                        })
                      }
                      className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]"
                    >
                      Dashboard
                    </button>
                  </div>
                </div>
              );
            } else {
              // Default UI for active or any other status
              statusComponent = (
                <div className="border border-childBorder rounded-2xl p-5 flex flex-col gap-y-5 w-full">
                  <div className="flex items-center gap-x-2">
                    <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                      <MedalStar />
                    </div>
                    <div>
                      <div className="text-[16px] font-semibold">
                        Account #{challenge.mt5_login_id}
                      </div>
                      <div className="text-[#6C6C6C] font-medium text-[14px]">
                        @Account#{challenge.mt5_login_id}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <div className="flex justify-between">
                      <div className="border-[#65D31D]  uppercase border rounded-2xl text-[#65D31D] font-semibold text-[16px] flex justify-center items-center dark:bg-[#00270B] bg-[#F4FDEE] w-[92px]">
                        {challenge.status}
                      </div>
                      <div className="text-[20px] font-semibold">
                        ${challenge.amount_total}k
                      </div>
                    </div>
                    <hr className="border border-childBorder" />
                    <div className="flex justify-between">
                      <div>
                        <div className="text-[#6C6C6C] text-[12px]">
                          Evaluation
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.account_stage).charAt(0).toUpperCase() +
                            String(challenge.account_stage).slice(1)}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="text-[#6C6C6C] text-[12px]">
                          Current Stage:
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.current_stage).charAt(0).toUpperCase() +
                            String(challenge.current_stage).slice(1)}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <div className="text-[#6C6C6C] text-[12px]">
                          Account
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.account_type).charAt(0).toUpperCase() +
                            String(challenge.account_type).slice(1)}
                        </div>
                      </div>
                      <div className="text-right">
                        <div className="text-[#6C6C6C] text-[12px]">
                          Total Profit
                        </div>
                        <div className="font-semibold text-[16px]">
                          {String(challenge.total_profit).charAt(0).toUpperCase() +
                            String(challenge.total_profit).slice(1)}
                        </div>
                      </div>
                    </div>
                    <hr className="border border-childBorder" />
                    <div>
                      <BlueProgressBar progress={0} />
                      <div className="flex justify-between">
                        <div className="text-[#6C6C6C] font-medium">
                          Progress
                        </div>
                        <div>0%</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-x-2">
                    <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                      <AccountView />
                    </div>

                    <button
                      // onClick={() =>
                      //   navigate(
                      //     `/challenge/dashboard/${challenge.mt5_login_id}`
                      //   )
                      // }
                      onClick={() =>
                        navigate("/challenge/dashboard", {
                          state: { mt5_login_id: challenge.mt5_login_id },
                        })
                      }
                      className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]"
                    >
                      Dashboard
                    </button>
                  </div>
                </div>
              );
            }

            return <div key={uuid}>{statusComponent}</div>;
          })}
      </div>
    </div>
  );
};

export default MyChallenge;
