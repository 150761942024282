import React, { useEffect, useState } from "react";
import images from "../../Assets/Assets";
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { getTradingActivity } from "../../Redux/Slices/NewChallengeSlices";

const TradingActivity = ({ mt5_login_id }) => {
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { tradingActivity } = useSelector((state) => state.newChallenge);
  // Ensure trading data exists
  const tradeData = tradingActivity?.data?.values || [];
  const totalPages = Math.ceil(tradeData.length / rowsPerPage);

  // Slice the data for the current page
  const paginatedData = tradeData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Handle page navigation
  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (mt5_login_id) {
      dispatch(getTradingActivity(mt5_login_id));
    }
  }, [mt5_login_id, dispatch]);

  return (
    <div className="w-full  bg-background rounded-2xl border-childBorder border p-5">
      {/* <div className="flex lg:justify-between lg:flex-row flex-col"> */}
      <div className="font-semibold ">Trading Activity</div>

      {/* <div className="flex items-center gap-x-5">
          <div className="bg-[#FAFCFD] border border-childBorder dark:bg-[#1C1C1C] dark:text-white text-[#6699FF] text-[13px] w-[159px] h-[38px] rounded-xl font-semibold flex justify-center items-center gap-x-1">
            Open Orders
            <ChevronDown size={20} />
          </div>
          <img src={images.more} alt="" />
        </div> */}
      {/* </div> */}

      <div className="mt-5 w-full overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-white uppercase bg-[#6699FF] ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Open Date
              </th>
              <th scope="col" className="px-6 py-3">
                symbol
              </th>
              <th scope="col" className="px-6 py-3">
                action
              </th>
              <th scope="col" className="px-6 py-3">
                lots
              </th>
              <th scope="col" className="px-6 py-3">
                Open price
              </th>
              <th scope="col" className="px-6 py-3">
                Close price
              </th>
              <th scope="col" className="px-6 py-3">
                Pips
              </th>
              <th scope="col" className="px-6 py-3">
                Net profit
              </th>
              <th scope="col" className="px-6 py-3">
                Gain
              </th>
            </tr>
          </thead>
          <tbody className="dark:text-white">
            {paginatedData.map((trade, index) => (
              <tr
                key={index}
                className={`${
                  (index + 1) % 2 === 0 ? "bg-tableBackground" : ""
                }`}
              >
                <td className="px-6 py-2">{trade.open_datetime}</td>
                <td className="px-6 py-2">{trade.symbol}</td>
                <td className="px-6 py-2">
                  <div
                    className={`w-[44px] h-[32px] dark:bg-[#011845] rounded-lg font-semibold flex justify-center items-center
      ${
        trade.action === "BUY"
          ? "bg-[#F4FDEE] text-[#65D31D]"
          : "bg-[#FFEAE9] text-[#FF4D49]"
      }`}
                  >
                    {trade.action}
                  </div>
                </td>
                <td className="px-6 py-2">{trade.lots}</td>
                <td className="px-6 py-2">{trade.open_price}</td>
                <td className="px-6 py-2 text-[#67CA24]">
                  {Number(trade.close_price).toFixed(2)}
                </td>
                <td className="px-6 py-2 text-[#67CA24]">
                  {Number(trade.pips).toFixed(2)}
                </td>
                <td className="px-6 py-2 text-[#67CA24]">{trade.profit}</td>
                <td className="px-6 py-2 text-[#67CA24]">0.03%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/*pagination*/}
      <div className="flex justify-end mt-2">
        <div className="flex items-center gap-x-5">
          <div className="text-[14px]">
            {currentPage} of {totalPages}
          </div>

          <div className="flex gap-x-5 items-center">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={`p-2 rounded-full ${
                currentPage === 1
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-200"
              }`}
            >
              <ChevronLeft size={20} />
            </button>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={`p-2 rounded-full ${
                currentPage === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-200"
              }`}
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingActivity;
