// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { getNewChallenge } from "../../../../Redux/Slices/NewChallengeSlices";

// const AccountType = () => {
//   // const [selected, setSelected] = useState("Aggressive");
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const storedStage = sessionStorage.getItem("stage");
//     if (!storedStage || storedStage === "undefined") {
//       sessionStorage.setItem("stage", "Standard");
//     }
//   }, []);

//   // Load stage from sessionStorage or default to "Standard"
//   const [selected, setSelected] = useState(
//     (sessionStorage.getItem("stage") || "Standard").toLowerCase()
//   );
//   const options = [
//     {
//       label: "Standard",
//       description: "Your account has standard drawdown limits",
//       isPopular: false,
//     },

//     {
//       label: "Aggressive",
//       description: "Increase your account drawdown limits",
//       isPopular: true,
//     },
//   ];

//   // Fetch challenge data when the selected stage changes
//   useEffect(() => {
//     sessionStorage.setItem("stage", selected);
//     dispatch(getNewChallenge(selected.toLowerCase()));
//   }, [selected, dispatch]);

//   return (
//     <div>
//       <div className="text-[#6699FF] font-semibold text-[20px] text-center lg:mt-0 mt-5">
//         Account Type
//       </div>
//       <div className="text-[16px] text-[#6C6C6C] font-normal text-center">
//         Standard / Aggressive
//       </div>
//       <div className="flex lg:justify-start lg:flex-row flex-col-reverse gap-y-4 lg:gap-x-4 mt-14">
//         {options.map((option) => (
//           <div key={option.label} className="relative">
//             {option.isPopular && (
//               <div className="absolute -top-10 bg-[#6699FF] text-center w-full left-1/2 transform -translate-x-1/2 text-sm font-bold text-white p-3 rounded-t-2xl">
//                 Most popular
//               </div>
//             )}
//             <div
//               onClick={() => setSelected(option.label)}
//               className={`cursor-pointer dark:bg-[#121212] dark:border-[#252525] flex flex-col items-center p-2 border box-shadow lg:w-[160px] ${
//                 selected === option.label
//                   ? "border-[#6699FF] border-2 bg-blue-50"
//                   : "border-gray-300"
//               } ${
//                 option.isPopular
//                   ? "rounded-t-none rounded-b-2xl"
//                   : "rounded-2xl"
//               }`}
//             >
//               <div
//                 className={`text-lg font-semibold ${
//                   selected === option.label ? "text-[#6699FF]" : "text-black dark:text-white "
//                 }`}
//               >
//                 {option.label}
//               </div>
//               <div className="text-gray-500 text-[14px] text-center mt-1">
//                 {option.description}
//               </div>
//               <div
//                 className={`mt-4 w-5 h-5 rounded-full border-2 flex items-center justify-center ${
//                   selected === option.label
//                     ? "border-[#6699FF]"
//                     : "border-gray-300"
//                 }`}
//               >
//                 {selected === option.label && (
//                   <div className="w-2.5 h-2.5 rounded-full bg-[#6699FF]"></div>
//                 )}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default AccountType;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getNewChallenge } from "../../../../Redux/Slices/NewChallengeSlices";

const AccountType = () => {
  const dispatch = useDispatch();
  const options = [
    {
      label: "Standard",
      description: "Your account has standard drawdown limits",
      isPopular: true,
    },
    {
      label: "Aggressive",
      description: "Increase your account drawdown limits",
      isPopular: false,
    },
  ];

  // Load acc_type from sessionStorage or default to "Standard"
  const [selected, setSelected] = useState(() => {
    return sessionStorage.getItem("acc_type") || "Standard";
  });

  useEffect(() => {
    sessionStorage.setItem("acc_type", selected);
    dispatch(getNewChallenge(selected)); // Fetch challenge data for selected type
  }, [selected, dispatch]);

  return (
    <div>
      <div className="text-[#6699FF] font-semibold text-[20px] text-center lg:mt-0 mt-5">
        Account Type
      </div>
      <div className="text-[16px] text-[#6C6C6C] font-normal text-center">
        Standard / Aggressive
      </div>
      <div className="flex lg:justify-start lg:flex-row flex-col-reverse gap-y-4 lg:gap-x-4 mt-14">
        {options.map((option) => (
          <div key={option.label} className="relative">
            {option.isPopular && (
              <div className="absolute -top-10 bg-[#6699FF] text-center w-full left-1/2 transform -translate-x-1/2 text-sm font-bold text-white p-3 rounded-t-2xl">
                Most popular
              </div>
            )}
            <div
              onClick={() => setSelected(option.label)}
              className={`cursor-pointer dark:bg-[#121212] dark:border-[#252525] flex flex-col items-center p-2 border box-shadow lg:w-[160px] ${
                selected === option.label
                  ? "border-[#6699FF] border-2 bg-blue-50"
                  : "border-gray-300"
              } ${
                option.isPopular
                  ? "rounded-t-none rounded-b-2xl"
                  : "rounded-2xl"
              }`}
            >
              <div
                className={`text-lg font-semibold ${
                  selected === option.label
                    ? "text-[#6699FF]"
                    : "text-black dark:text-white"
                }`}
              >
                {option.label}
              </div>
              <div className="text-gray-500 text-[14px] text-center mt-1">
                {option.description}
              </div>
              <div
                className={`mt-4 w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                  selected === option.label
                    ? "border-[#6699FF]"
                    : "border-gray-300"
                }`}
              >
                {selected === option.label && (
                  <div className="w-2.5 h-2.5 rounded-full bg-[#6699FF]"></div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountType;
