import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

const Loader = () => {
  return (
    <div className="flex justify-center items-center">
      <PropagateLoader loading={true} color="#6699FF" />
    </div>
  );
};

export default Loader;
