import React, { useState, useEffect } from "react";

const ConfirmOrder = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    zip_code: "",
    state: "",
    country: "",
  });

  // Load saved data from sessionStorage when component mounts
  useEffect(() => {
    const savedData = sessionStorage.getItem("user_data");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const handleChange = (e) => {
    const updatedFormData = { ...formData, [e.target.name]: e.target.value };

    // Save to sessionStorage whenever user types
    sessionStorage.setItem("user_data", JSON.stringify(updatedFormData));

    setFormData(updatedFormData);
  };

  return (
    <div>
      <div className="text-[#6699FF] font-semibold text-[20px] text-center lg:mt-0 mt-5">
        Confirm Order
      </div>
      <div className="text-[16px] text-[#6C6C6C] font-normal text-center">
        Billing Details
      </div>
      <form className="mt-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
          <input
            className="w-full h-[50px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="first_name"
            placeholder="First Name"
            value={formData.first_name}
            onChange={handleChange}
          />
          <input
            className="w-full h-[50px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="last_name"
            placeholder="Last Name"
            value={formData.last_name}
            onChange={handleChange}
          />
          <input
            className="w-full h-[50px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            className="w-full h-[50px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <hr className="my-5" />

        <div className="grid grid-cols-1 gap-5">
          <input
            className="w-full h-[50px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
          <input
            className="w-full h-[50px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="city"
            placeholder="Town/City"
            value={formData.city}
            onChange={handleChange}
          />
          <input
            className="w-full h-[50px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="zip_code"
            placeholder="Zip Code"
            value={formData.zip_code}
            onChange={handleChange}
          />
          <select
            className="w-full h-[50px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="state"
            value={formData.state}
            onChange={handleChange}
          >
            <option value="" disabled>
              State
            </option>
            <option value="WesternAustralia">Western Australia</option>
            <option value="NewSouthWales">New South Wales</option>
            <option value="Queensland">Queensland</option>
            <option value="Victoria">Victoria</option>
            <option value="SouthAustralia">South Australia</option>
          </select>
          <select
            className="w-full h-[50px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="country"
            value={formData.country}
            onChange={handleChange}
          >
            <option value="" disabled>
              Country
            </option>
            <option value="Australia">Australia</option>
            <option value="USA">USA</option>
            <option value="UK">UK</option>
            <option value="Canada">Canada</option>
            <option value="India">India</option>
          </select>
        </div>
      </form>
    </div>
  );
};

export default ConfirmOrder;
