import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { getMonthlyAnalytics } from "../../../Redux/Slices/NewChallengeSlices";

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HorizontalBarChart = ({ mt5_login_id }) => {
  const dispatch = useDispatch();
  const { monthlyAnalytics } = useSelector((state) => state.newChallenge); 
  useEffect(() => {
    if (mt5_login_id) {
      dispatch(getMonthlyAnalytics(mt5_login_id));
    }
  }, [dispatch, mt5_login_id]);

  // Labels and data setup
  // const labels = ["JAN", "FEB", "MAR", "APR", "MAY"]; // Replace Utils.months with static labels
  const labels =
    monthlyAnalytics && monthlyAnalytics.data
      ? Object.keys(monthlyAnalytics.data)
      : [];
  const values =
    monthlyAnalytics && monthlyAnalytics.data
      ? Object.values(monthlyAnalytics.data)
      : [];
  const data = {
    labels: labels,
    datasets: [
      {
        axis: "y", // Horizontal bar chart

        data: values,
        fill: false,
        backgroundColor: [
          "rgba(102, 153, 255, 1)",
          "rgba(38, 198, 249, 1)",
          "rgba(102, 153, 255, 1)",
          "rgba(102, 153, 255, 1)",
          "rgba(102, 153, 255, 1)",
        ],
        borderRadius: 10,
        barThickness: 25,
      },
    ],
  };

  // Chart configuration
  const options = {
    indexAxis: "y", // Horizontal bar chart
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: false, // We don't want to start from 0
        min: 0.5, // Start at 0.5
        max: 1.5, // End at 1.5
        ticks: {
          stepSize: 0.25, // Step size of 0.25
          callback: function (value) {
            return value.toFixed(2); // Ensures 2 decimal places without rounding
          },
        },
        grid: {
          borderDash: [5, 5], // Dotted line for x-axis grid
        },
      },
      y: {
        grid: {
          display: false, // Hide grid lines on the y-axis
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;
