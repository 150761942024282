import { ChevronDown, CircleArrowLeft, Moon, SunMedium } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/Sidebar/propmodelLogo.svg";
import { Menus } from "./MenuData";
import { ThemeContext } from "../../Contexts/ThemeProvider";

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [openSubMenus, setOpenSubMenus] = useState({});
  const location = useLocation();

  const { storeValue, setStoreValue } = useContext(ThemeContext);
  // console.log(storeValue);
  useEffect(() => {
    if (storeValue === "dark") {
      document.documentElement.setAttribute("data-theme", storeValue);
    } else {
      document.documentElement.setAttribute("data-theme", storeValue);
    }
  }, [storeValue]);

  const toggleSubMenu = (index) => {
    setOpenSubMenus((prev) => {
      // Create a new object to avoid mutating the previous state
      const newSubMenus = { ...prev };

      // If the clicked submenu is already open, toggle it off
      if (newSubMenus[index]) {
        newSubMenus[index] = false;
      } else {
        // Close all submenus and open the clicked one
        for (const key in newSubMenus) {
          newSubMenus[key] = false;
        }
        newSubMenus[index] = true;
      }

      return newSubMenus;
    });
  };

  return (
    <div className="flex lg:block hidden">
      {/* Sidebar */}
      <div
        className={`h-screen p-5 pt-8 flex flex-col justify-between ${
          open ? "w-64" : "w-20"
        } duration-300 relative`}
      >
        <CircleArrowLeft
          className={`bg-white text-[#6699FF] text-3xl rounded-full absolute -right-3 top-9 border border-[#6699FF] cursor-pointer ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />

        <div>
          <div className="inline-flex cursor-pointer">
            <img
              src={logo}
              alt=""
              className={`text-4xl rounded block float-left mr-1 duration-500 ${
                open && "rotate-[360deg]"
              }`}
            />

            <h1
              className={`origin-left font-bold text-2xl duration-300 ${
                !open && "scale-0"
              }`}
            >
              propmodel
            </h1>
          </div>

          <ul className="pt-2">
            {Menus.map((menu, index) => (
              <div key={index}>
                {/* Render Heading or <hr> */}
                {menu.heading &&
                  (open ? (
                    <h3 className="text-[#C6C6C6] text-xs uppercase font-semibold mt-4 pl-2">
                      {menu.heading}
                    </h3>
                  ) : (
                    <hr className="border-gray-300 my-4" />
                  ))}
                {/* Render Menu Item */}
                <Link to={menu.path}></Link>
                <li
                  className={`text-sm flex items-center gap-x-4 justify-between cursor-pointer p-2 rounded-md ${
                    menu.spacing ? "mt-9" : "mt-2"
                  } group hover:bg-[#EDF4FF] dark:hover:bg-[#011845] dark:text-white  group-hover:text-[#6699FF]  ${
                    location.pathname === menu.path
                      ? "bg-[#EDF4FF]  dark:bg-[#011845] text-[#6699FF]"
                      : "bg-transparent text-[#6C6C6C]"
                  }`}
                >
                  <Link
                    to={menu.path}
                    className="flex items-center gap-x-[10px] w-full"
                  >
                    <span className="text-2xl block float-left">
                      <div
                        className={`menuitem ${
                          location.pathname === menu.path ? "active" : ""
                        } ${
                          storeValue === "dark" &&
                          location.pathname === menu.path
                            ? "darkActive"
                            : ""
                        }`}
                      >
                        {menu.icon}
                      </div>
                    </span>

                    <span
                      className={`text-base font-medium flex-1 ${
                        location.pathname === menu.path
                          ? "group-hover:text-[#6699FF] dark:text-white"
                          : "text-[#6C6C6C]"
                      } hover:text-[#6699FF] dark:hover:text-white ${
                        !open && "hidden"
                      }`}
                    >
                      {menu.title}
                    </span>
                  </Link>
                  {menu.submenu && open && (
                    <ChevronDown
                      className={`transform ${
                        openSubMenus[index] && "rotate-180"
                      } group-hover:text-[#6699FF]`}
                      onClick={() => toggleSubMenu(index)}
                    />
                  )}
                </li>
                {menu.submenu && openSubMenus[index] && open && (
                  <ul>
                    {menu.submenuItems.map((submenuItem, subIndex) => (
                      <li
                        key={subIndex}
                        className="text-[#6C6C6C] text-sm px-10 flex items-center gap-x-4 cursor-pointer p-2 hover:bg-[#EDF4FF] dark:hover:bg-[#011845] dark:hover:text-white hover:text-[#6699FF] rounded-md"
                      >
                        <Link to={submenuItem.path}>{submenuItem.title}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </ul>
        </div>

        <div>
          <div className="pt-5 flex justify-center">
            <div
              className={`bg-[#EEEEEE] dark:bg-[#0D0D0D] rounded ${
                open ? "w-[240px]" : "w-[80px] flex-col"
              } flex p-2 duration-300`}
            >
              {open ? (
                <>
                  <button
                    onClick={() => setStoreValue("dark")}
                    className={`${
                      open ? "w-[114px]" : "w-[50px]"
                    } h-[32px] rounded font-semibold ${
                      open
                        ? " dark:bg-[#011845] text-black dark:text-white"
                        : "bg-transparent text-[#6C6C6C]"
                    } flex justify-center items-center gap-2 duration-300`}
                  >
                    <Moon />

                    {open && "Dark"}
                  </button>
                  <button
                    onClick={() => setStoreValue("light")}
                    className={`${
                      open ? "w-[114px]" : "w-[50px]"
                    } h-[32px] rounded font-semibold ${
                      open
                        ? "bg-[#FFFFFF] text-black dark:bg-[#0D0D0D] dark:text-white"
                        : "bg-transparent text-[#6C6C6C]"
                    } flex justify-center items-center gap-2 duration-300`}
                  >
                    <SunMedium />
                    {open && "Light"}
                  </button>
                </>
              ) : (
                <button
                  onClick={() =>
                    setStoreValue(storeValue === "light" ? "dark" : "light")
                  }
                  className={`w-[50px] h-[32px] rounded font-semibold ${
                    storeValue === "light"
                      ? "bg-[#FFFFFF] text-black "
                      : "bg-[#011845] text-white "
                  } flex justify-center items-center duration-300`}
                >
                  {storeValue === "light" ? <SunMedium /> : <Moon />}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
