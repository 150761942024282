import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Components/Login/Login";
import Signup from "../Components/Signup/Signup";
import Layout from "../Layout/Layout";
import Home from "../Pages/Home/Home";
import NewChallenge from "../Pages/Challenge/Newchallenge/NewChallenge";
import MyChallenge from "../Pages/Challenge/MyChallenge/MyChallenge";
import Competitions from "../Pages/Competitions/Competitions";
import Leaderboard from "../Pages/Leaderboard/Leaderboard";
import CompetitionDetails from "../Pages/Competitions/CompetitionDetails";
import Dashboard from "../Pages/Challenge/Dashboard";
import AdvancedChart from "../Pages/Tools/AdvancedChart";
import EconomicCalendar from "../Pages/Tools/EconomicCalendar";
import Downloads from "../Pages/Tools/Downloads";
import TradingCourses from "../Pages/Education/TradingCourses/TradingCourses";
import PropmodelTutorials from "../Pages/Education/PropModelTutorials/PropmodelTutorials";
import TradingCoursesFullVideo from "../Pages/Education/TradingCourses/TradingCoursesFullVideo";
import PropModelLearnMore from "../Pages/Education/PropModelTutorials/PropModelLearnMore";
import Achievements from "../Pages/Mastery/Achievements";
import Certificates from "../Pages/Mastery/Certificates";
import Affiliate from "../Pages/Affiliate/Affiliate";
import Profile from "../Pages/Account/Profile/Profile";
import Security from "../Pages/Account/Security/Security";
import Billing from "../Pages/Account/Billing/Billing";
import ProtectedRoute from "./ProtectedRoute";
import ForgetPassword from "../Components/ForgetPassword/ForgetPassword";
import ResetPassword from "../Components/ResetPassword/ResetPassword";
import PublicRoute from "./PublicRoute";

const PagesRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <Signup />
          </PublicRoute>
        }
      />
      <Route
        path="/forget-password"
        element={
          <PublicRoute>
            <ForgetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />

      {/* Protected Routes (with Layout) */}
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Layout>
              <Home />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/challenge/dashboard"
        element={
          <ProtectedRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/challenge/new-challenge"
        element={
          <Layout>
            <NewChallenge />
          </Layout>
        }
      />

      <Route
        path="/challenge/my-challenge"
        element={
          <Layout>
            <MyChallenge />
          </Layout>
        }
      />

      <Route
        path="/competitions"
        element={
          <Layout>
            <Competitions />
          </Layout>
        }
      />
      <Route
        path="/competition-details"
        element={
          <Layout>
            <CompetitionDetails />
          </Layout>
        }
      />
      <Route
        path="/leaderboard"
        element={
          <Layout>
            <Leaderboard />
          </Layout>
        }
      />
      <Route
        path="/tools/advanced-chart"
        element={
          <Layout>
            <AdvancedChart />
          </Layout>
        }
      />
      <Route
        path="/tools/economic-calendar"
        element={
          <Layout>
            <EconomicCalendar />
          </Layout>
        }
      />
      <Route
        path="/tools/downloads"
        element={
          <Layout>
            <Downloads />
          </Layout>
        }
      />
      <Route
        path="/education/trading-courses"
        element={
          <Layout>
            <TradingCourses />
          </Layout>
        }
      />
      <Route
        path="/education/trading-courses/course-video"
        element={
          <Layout>
            <TradingCoursesFullVideo />
          </Layout>
        }
      />
      <Route
        path="/education/propmodel-tutorials"
        element={
          <Layout>
            <PropmodelTutorials />
          </Layout>
        }
      />
      <Route
        path="/education/propmodel-tutorials/learn-more"
        element={
          <Layout>
            <PropModelLearnMore />
          </Layout>
        }
      />
      <Route
        path="/mastery/achievements"
        element={
          <Layout>
            <Achievements />
          </Layout>
        }
      />
      <Route
        path="/mastery/certificates"
        element={
          <Layout>
            <Certificates />
          </Layout>
        }
      />
      <Route
        path="/affiliate"
        element={
          <Layout>
            <Affiliate />
          </Layout>
        }
      />
      <Route
        path="/account/profile"
        element={
          <Layout>
            <Profile />
          </Layout>
        }
      />
      <Route
        path="/account/security"
        element={
          <Layout>
            <Security />
          </Layout>
        }
      />
      <Route
        path="/account/billing"
        element={
          <Layout>
            <Billing />
          </Layout>
        }
      />
    </Routes>
  );
};

export default PagesRoutes;
