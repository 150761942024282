import React, { useEffect } from "react";
import more from "../../Assets/Challenge/more.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAdvancedStatistics } from "../../Redux/Slices/NewChallengeSlices";

const BlueProgressBar = ({ progress }) => {
  return (
    <div className="w-full sm:w-[144px] bg-[#EDF4FF] dark:bg-[#011845] rounded-full h-2 mt-2">
      <div
        className="bg-[#6699FF] h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const GreenProgressBar = ({ progress }) => {
  return (
    <div className="w-full sm:w-[144px] bg-[#F4FDEE] dark:bg-[#011845] rounded-full h-2 mt-2">
      <div
        className="bg-[#65D31D] h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const AdvancedStatistics = ({ mt5_login_id }) => {
  const dispatch = useDispatch();
  const { advancedStatistics } = useSelector((state) => state.newChallenge);

  useEffect(() => {
    if (mt5_login_id) {
      dispatch(getAdvancedStatistics(mt5_login_id));
    }
  }, [mt5_login_id, dispatch]);

  return (
    <div className="w-full bg-background rounded-2xl border-childBorder border p-5">
      <div className="flex justify-between items-center">
        <div className="font-semibold text-lg">Advanced Statistics</div>
        <div>
          <img src={more} alt="" className="cursor-pointer" />
        </div>
      </div>
      <div className=" mt-5  gap-4 flex overflow-x-auto">
        <div className=" w-full border-childBorder border rounded-lg p-4">
          <div className="flex justify-between gap-x-2">
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Trades</div>
              <div className="font-semibold">128</div>
            </div>
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C] text-left">Total Lots:</div>
              <div className="font-semibold  text-left">
                {advancedStatistics?.data?.total_lots}
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2 mt-2">
            <div className="flex flex-col gap-y-2">
              <div className="text-[#6C6C6C] w-[144px] ">Profitability</div>
              <div className="font-semibold">
                {advancedStatistics?.data?.profitability}%
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <div className="text-[#6C6C6C] w-[144px]">Shorts Won</div>
              <div className="font-semibold">$850.62</div>
            </div>
          </div>
          <BlueProgressBar progress={85} />
        </div>

        <div className="w-full border-childBorder border rounded-lg p-4">
          <div className="flex justify-between gap-x-2">
            <div className="flex flex-col gap-y-2 w-[155px]">
              <div className="text-[#6C6C6C] ">Best Trade</div>
              <div className="font-semibold flex gap-x-1">
                ${advancedStatistics?.data?.best_trade_profit}
                <span className="text-[#6C6C6C] font-normal">(July 28)</span>
              </div>
            </div>
            <div className="flex flex-col gap-y-2 w-[155px]">
              <div className="text-[#6C6C6C]">Worst Trade</div>
              <div className="font-semibold text-[#FF4D49] flex gap-x-1 text-left">
                ${advancedStatistics?.data?.worst_trade_profit}
                <span className="text-[#6C6C6C] font-normal">(July 28)</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2 mt-2">
            <div className="flex flex-col gap-y-2 w-[155px]">
              <div className="text-[#6C6C6C]">Longs Won</div>
              <div className="font-semibold flex gap-x-2">
                85% <span className="text-[#6C6C6C] font-normal">(85/100)</span>
              </div>
            </div>
            <div className="flex flex-col gap-y-2 w-[155px]">
              <div className="text-[#6C6C6C]">Shorts Won</div>
              <div className="font-semibold flex gap-x-2">
                $5,028.01{" "}
                <span className="text-[#6C6C6C] font-normal">(20/28)</span>
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <BlueProgressBar progress={50} />
            <GreenProgressBar progress={65} />
          </div>
        </div>

        <div className=" w-full border-childBorder border rounded-lg p-4">
          <div className="flex justify-between gap-x-2">
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Most Traded</div>
              <div className="font-semibold">
                {advancedStatistics?.data?.most_traded_asset}
              </div>
            </div>
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Profit Factor</div>
              <div className="font-semibold">
                {advancedStatistics?.data?.profit_factor}
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2 mt-2">
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Sharpe Ratio:</div>
              <div className="font-semibold">1.01</div>
            </div>
            <div className="flex flex-col gap-y-2 w-[144px]">
              <div className="text-[#6C6C6C]">Risk/Reward Ratio</div>
              <div className="font-semibold">1.14</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedStatistics;
