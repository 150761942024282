import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CHALLENGE_BASE_URL } from "../../Config/config";

// Get new Challenge
export const getNewChallenge = createAsyncThunk(
  "newChallenge/getNewChallenge",
  async (_, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) throw new Error("No authentication token found");

      const storedStage = sessionStorage.getItem("stage") || "single";
      const accountType = sessionStorage.getItem("acc_type") || "standard";
      let price = sessionStorage.getItem("account_balance") || "$2500";

      // Clean price (remove $ and commas)
      price = price.replace(/[$,]/g, "");

      const response = await axios.get(
        `${CHALLENGE_BASE_URL}/api/v1/challenge-price-detail?stage=${storedStage.toLowerCase()}&type=${accountType.toLowerCase()}&price=${price}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch new challenge"
      );
    }
  }
);

// Get my Challenge
export const getMyChallenge = createAsyncThunk(
  "challenges/getMyChallenge",
  async (_, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found");
      }

      const response = await axios.get(
        `${CHALLENGE_BASE_URL}/api/v1/challenges`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch challenges"
      );
    }
  }
);

// get account credentials
export const getAccountCredentials = createAsyncThunk(
  "challenges/getAccountCredentials",
  async (mt5_login_id, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        return rejectWithValue("No authentication token found");
      }

      if (!mt5_login_id) {
        return rejectWithValue("MT5 Login ID is required");
      }

      const response = await axios.get(
        `${CHALLENGE_BASE_URL}/api/v1/account-credential/${mt5_login_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch account credentials"
      );
    }
  }
);

// get Account Trade Details
export const getAccountTradeDetails = createAsyncThunk(
  "account/getAccountTradeDetails",
  async (mt5_login_id, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return rejectWithValue("No authentication token found");
      }

      const response = await axios.get(
        `${CHALLENGE_BASE_URL}/api/v1/account-trade-detail/${mt5_login_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch account statistics"
      );
    }
  }
);

// get Advanced statistics
export const getAdvancedStatistics = createAsyncThunk(
  "account/getAdvancedStatistics",
  async (mt5_login_id, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return rejectWithValue("No authentication token found");
      }

      const response = await axios.get(
        `${CHALLENGE_BASE_URL}/api/v1/account-advanced-statistics/${mt5_login_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch account statistics"
      );
    }
  }
);

// get Trading Activity
export const getTradingActivity = createAsyncThunk(
  "account/getTradingActivity",
  async (mt5_login_id, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return rejectWithValue("No authentication token found");
      }

      const response = await axios.get(
        `${CHALLENGE_BASE_URL}/api/v1/trade-activity/${mt5_login_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message ||
          error?.message ||
          "Failed to fetch trading activity"
      );
    }
  }
);
// get Monthly Analytics
export const getMonthlyAnalytics = createAsyncThunk(
  "account/getMonthlyAnalytics",
  async (mt5_login_id, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return rejectWithValue("No authentication token found");
      }

      const response = await axios.get(
        `${CHALLENGE_BASE_URL}/api/v1/monthly-analytics/${mt5_login_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message ||
          error?.message ||
          "Failed to fetch Monthly Analytics"
      );
    }
  }
);

// Post Request Payout
export const requestPayout = createAsyncThunk(
  "account/requestPayout",
  async ({ mt5_login_id, amount }, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("token");

      if (!token) {
        return rejectWithValue("No authentication token found");
      }

      const response = await axios.post(
        `${CHALLENGE_BASE_URL}/api/v1/request-payout`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data?.message ||
          error?.message ||
          "Failed to request payout"
      );
    }
  }
);

const newChallengeSlice = createSlice({
  name: "newChallenge",
  initialState: {
    newChallengeData: null,
    getMyChallengeData: null,
    accountCredentials: null,
    accountTradeDetails: null,
    advancedStatistics: null,
    tradingActivity: null,
    monthlyAnalytics: null,
    payoutData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get new Challenge
      .addCase(getNewChallenge.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNewChallenge.fulfilled, (state, action) => {
        state.loading = false;
        state.newChallengeData = action.payload;
      })
      .addCase(getNewChallenge.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //Get my Challenge
      .addCase(getMyChallenge.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyChallenge.fulfilled, (state, action) => {
        state.loading = false;
        state.getMyChallengeData = action.payload;
      })
      .addCase(getMyChallenge.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //get account credentials
      .addCase(getAccountCredentials.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAccountCredentials.fulfilled, (state, action) => {
        state.loading = false;
        state.accountCredentials = action.payload;
      })
      .addCase(getAccountCredentials.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // get Account Trade Details
      .addCase(getAccountTradeDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAccountTradeDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.accountTradeDetails = action.payload;
      })
      .addCase(getAccountTradeDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      })

      //get Advanced Statistics
      .addCase(getAdvancedStatistics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdvancedStatistics.fulfilled, (state, action) => {
        state.loading = false;
        state.advancedStatistics = action.payload;
      })
      .addCase(getAdvancedStatistics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      })

      //get Trade Activity
      .addCase(getTradingActivity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTradingActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.tradingActivity = action.payload;
      })
      .addCase(getTradingActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      })
      //get Monthly Analytics
      .addCase(getMonthlyAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMonthlyAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.monthlyAnalytics = action.payload;
      })
      .addCase(getMonthlyAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      })
      //post payout request
      .addCase(requestPayout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(requestPayout.fulfilled, (state, action) => {
        state.loading = false;
        state.payoutData = action.payload;
      })
      .addCase(requestPayout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      });
  },
});

export default newChallengeSlice.reducer;
