import React, { useEffect, useRef, useState } from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import EvaluationStages from "./StepComponents/EvaluationStages";
import AccountBalance from "./StepComponents/AccountBalance";
import AccountType from "./StepComponents/AccountType";
import ConfirmOrder from "./StepComponents/ConfirmOrder";
import Checkout from "./StepComponents/Checkout";
import ThankYouPage from "./StepComponents/ThankYouPage";
import {
  AccountTypeSvg,
  CheckOutSvg,
  DollarSvg,
  StarSvg,
} from "../../../Assets/Challenge/SvgNewChallenge";
import { useContext } from "react";
import { ThemeContext } from "../../../Contexts/ThemeProvider";
import { PopupContext } from "../../../Contexts/PopupContext";
import { useDispatch, useSelector } from "react-redux";
import { getNewChallenge } from "../../../Redux/Slices/NewChallengeSlices";

const StartYourChallenge = () => {
  const popupRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const { isPopupOpen, closePopup } = useContext(PopupContext);

  const dispatch = useDispatch();
  const { newChallengeData, loading, error } = useSelector(
    (state) => state.newChallenge
  );

  // Fetch user data
  useEffect(() => {
    dispatch(getNewChallenge());
  }, [dispatch]);

  // Steps for the Stepper
  const steps = [
    {
      label: "Evaluation Stages",
      description: "Instant - Triple",
      icon: <StarSvg />,
    },
    {
      label: "Account Balance",
      description: "$5,000 - $200,000",
      icon: <DollarSvg />,
    },
    {
      label: "Account Type",
      description: "Standard / Aggressive",
      icon: <AccountTypeSvg />,
    },
    {
      label: "Confirm Order",
      description: "Billing Details",
      icon: <ConfirmOrder />,
    },
    { label: "Checkout", description: "Payment Method", icon: <CheckOutSvg /> },
  ];

  const { storeValue } = useContext(ThemeContext);

  const stepStyle = {
    "& .MuiStepConnector-line": {
      borderColor: storeValue === "dark" ? "#011845" : "#EDF4FF",
      marginTop: "5px",
      borderWidth: "5px",
      zIndex: -1,
    },
    "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
      borderColor: "#6699FF", // Active step connector color (blue)
    },
    "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
      borderColor: "#65D31D", // Completed step connector color (green)
    },

    "& .MuiStepIcon-root": {
      fontSize: "2.8rem",
      padding: "2px",
      backgroundColor: "#ffffff", // Default circle color
      borderRadius: "50%", // Ensures the background is a circle
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      zIndex: 1,
    },
    "& .MuiStepIcon-root.Mui-completed": {
      color: "#65D31D", // Completed step color (green)
    },
    "& .MuiStepIcon-root.Mui-active": {
      color: "#6699FF", // Active step color (blue)
    },
    "& .MuiStepIcon-text": {
      fill: "#FFFFFF", // Change the tick mark color (inside the circle)
    },
    "& .MuiStepper-root .MuiStepIcon-root.Mui-active": {
      backgroundColor: "red",
    },
  };

  // Mapping steps to components
  const stepComponents = [
    <EvaluationStages />,
    <AccountBalance />,
    <AccountType />,
    <ConfirmOrder />,
    <Checkout />,
    <ThankYouPage />,
  ];

  // Close the popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
        const token = sessionStorage.getItem("token");
        sessionStorage.clear();

        // Restore the token
        if (token) {
          sessionStorage.setItem("token", token);
        }
      }
    };

    const preventScroll = () => {
      document.body.style.overflow = "hidden";
    };

    const enableScroll = () => {
      document.body.style.overflow = "auto";
    };

    document.addEventListener("mousedown", handleClickOutside);
    preventScroll();

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      enableScroll();
    };
  }, [isPopupOpen, closePopup]);

  // Handle next step
  const handleNext = () => {
    dispatch(getNewChallenge());
    setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length));
  };

  // Handle previous step
  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  if (!isPopupOpen) return null;
  return (
    <div className="fixed inset-0 z-50  bg-gray-800 bg-opacity-50  lg:py-5 flex lg:justify-center lg:items-center justify-end items-end ">
      <div
        ref={popupRef}
        className=" bg-background lg:rounded-2xl rounded-t-3xl p-6 lg:w-[1100px] overflow-y-auto flex flex-col justify-between h-[85vh] lg:h-auto"
      >
        <div>
          <h2 className="font-semibold text-center lg:text-[34px] text-[16px]">
            Build the plan that works best for you!
          </h2>
          <p className="text-[#6C6C6C] lg:text-[16px] text-[14px] text-center font-medium">
            Select from the options below to adjust your challenge parameters.
          </p>

          <div className="mt-5 lg:block hidden">
            {/* Stepper */}
            <Stepper activeStep={activeStep} alternativeLabel sx={stepStyle}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>
                    <div className="flex flex-col">
                      <span
                        className={`font-semibold text-[16px] ${
                          index < activeStep
                            ? "text-[#65D31D]" // Done step color (green)
                            : activeStep === index
                            ? "text-[#6699FF]" // Active step color (blue)
                            : "text-[#6C6C6C]" // Default color (gray)
                        }`}
                      >
                        {step.label}
                      </span>
                      <span className="text-sm text-[#6C6C6C]">
                        {step.description}
                      </span>
                    </div>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>

        <div>
          <div className="flex lg:justify-between lg:flex-row flex-col-reverse mt-5 ">
            {/* Left Section */}
            <div className="lg:w-2/3 lg:pr-6 flex flex-col justify-between lg:border-r border-childBorder ">
              <div>
                {/* Render ThankYouPage when activeStep reaches the last step */}
                {activeStep === steps.length ? (
                  <ThankYouPage />
                ) : (
                  stepComponents[activeStep]
                )}
              </div>

              <div>
                <hr className="my-5 border-childBorder" />
                <div className="mt-5 flex justify-end space-x-2">
                  {/* Buttons for navigation */}
                  {activeStep === 0 ? (
                    <button
                      onClick={() => {
                        sessionStorage.clear();
                        closePopup();
                      }}
                      cl
                      className="bg-[#FAFCFD] border-childBorder dark:bg-[#1C1C1C] border rounded-lg text-[#6699FF] w-full lg:w-[94px] h-[38px]"
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className="bg-[#FAFCFD] border-childBorder dark:bg-[#1C1C1C] border rounded-lg text-[#6699FF] w-full lg:w-[94px] h-[38px]"
                    >
                      Back
                    </button>
                  )}

                  {/* Conditionally render "My Challenge" button on the last page */}
                  {activeStep === steps.length ? (
                    <button
                      onClick={closePopup}
                      className="bg-[#6699FF] w-full lg:w-[160px] h-[38px] rounded-lg font-semibold text-white"
                    >
                      My Challenges
                    </button>
                  ) : (
                    <button
                      onClick={handleNext}
                      className="bg-[#6699FF] w-full lg:w-[77px] h-[38px] rounded-lg font-semibold text-white"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* Right Section (Unchanged) */}
            <div className="lg:w-[320px] border border-[#6699FF] dark:bg-[#1C1C1C] rounded-2xl text-black  p-5">
              <div
                style={{
                  background:
                    "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
                }}
                className="p-5 text-white rounded-2xl"
              >
                <h3 className="text-sm font-normal">Total: (USD)</h3>
                <p className="text-3xl font-bold my-4">
                  ${newChallengeData?.data?.current_price}
                </p>
                <hr className="my-4" />
                <div className="space-y-2 text-sm">
                  <div className="flex justify-between">
                    <span>Discount:</span>
                    <span>$0.00</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Challenge:</span>
                    <span>$159.00</span>
                  </div>
                </div>
              </div>

              <div className="flex justify-between mt-2">
                <div className="flex flex-col ">
                  <span className="text-[#6C6C6C] text-[12px] font-normal">
                    Balance
                  </span>
                  <span className="text-[20px] text-[#2B2A2A] dark:text-white  font-semibold">
                    {sessionStorage.getItem("account_balance") || "$2500"}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="text-[#6C6C6C] text-[12px] font-normal">
                    Leverage
                  </span>
                  <span className="text-[20px] text-[#2B2A2A] dark:text-white   font-semibold">
                    {newChallengeData?.data.account_leverage === 100
                      ? "1.00"
                      : newChallengeData?.data.account_leverage}
                  </span>
                </div>
              </div>
              <hr className="my-2" />
              <div className="flex justify-between">
                <div className="flex flex-col ">
                  <span className="text-[#6C6C6C] text-[12px] font-normal">
                    Evaluation Stages
                  </span>
                  <span className="text-[20px] font-semibold text-[#2B2A2A] dark:text-white  ">
                    {newChallengeData?.data.stage}
                  </span>
                </div>
                <div className="flex flex-col text-right">
                  <span className="text-[#6C6C6C] text-[12px] font-normal">
                    Account
                  </span>
                  <span className="text-[20px] text-[#2B2A2A]  font-semibold dark:text-white ">
                    {sessionStorage.getItem("acc_type") || "Standard"}
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <div className="flex flex-col ">
                  <span className="text-[#6C6C6C] text-[12px] font-normal">
                    Profit Split
                  </span>
                  <span className="text-[20px] text-[#2B2A2A] dark:text-white   font-semibold">
                    {newChallengeData?.data.profit_split}%
                  </span>
                </div>
                <div className="flex flex-col text-right">
                  <span className="text-[#6C6C6C] text-[12px] font-normal">
                    Profit Target
                  </span>
                  <span className="text-[20px] text-[#2B2A2A]  dark:text-white  font-semibold">
                    {(() => {
                      let target = newChallengeData?.data?.profit_target;
                      if (typeof target === "string") {
                        try {
                          target = JSON.parse(target);
                        } catch (error) {
                          console.error("Error parsing profit_target:", error);
                        }
                      }
                      return Array.isArray(target) ? target.join("/") : target;
                    })()}
                    %
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <div className="flex flex-col ">
                  <span className="text-[#6C6C6C] text-[12px] font-normal">
                    Max Drawdown
                  </span>
                  <span className="text-[20px] text-[#2B2A2A]  dark:text-white  font-semibold">
                    {newChallengeData?.data.max_drawdown}%
                  </span>
                </div>
                <div className="flex flex-col text-right">
                  <span className="text-[#6C6C6C] text-[12px] font-normal">
                    Daily Drawdown:
                  </span>
                  <span className="text-[20px] text-[#2B2A2A] dark:text-white  font-semibold">
                    {newChallengeData?.data.daily_drawdown}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartYourChallenge;
