import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNewChallenge } from "../../../../Redux/Slices/NewChallengeSlices";

const EvaluationStages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const storedStage = sessionStorage.getItem("stage");
    if (!storedStage || storedStage === "undefined") {
      sessionStorage.setItem("stage", "single");
    }
  }, []);

  // Load stage from sessionStorage or default to "single"
  const [selected, setSelected] = useState(
    (sessionStorage.getItem("stage") || "single").toLowerCase()
  );

  const options = [
    { label: "Single", description: "One evaluation for a funded account" },
    { label: "Double", description: "Two evaluations for a funded account" },
    { label: "Triple", description: "Three evaluations for a funded account" },
    { label: "Instant", description: "No evaluation for a funded account" },
  ];

  // Fetch challenge data when the selected stage changes
  useEffect(() => {
    sessionStorage.setItem("stage", selected);
    dispatch(getNewChallenge(selected.toLowerCase()));
  }, [selected, dispatch]);

  return (
    <div>
      <div className="text-[#6699FF] font-semibold text-[20px] text-center lg:mt-0 mt-5">
        Evaluation Stages
      </div>
      <div className="text-[16px] text-[#6C6C6C] font-normal text-center">
        Instant - Triple
      </div>

      <div className="flex lg:justify-center lg:flex-row flex-col lg:gap-x-4 gap-y-4 mt-14">
        {options.map((option) => {
          const isSelected = selected === option.label.toLowerCase();
          const isPopular = option.label === "Single"; // ✅ Only Single is popular

          return (
            <div key={option.label} className="relative">
              {isPopular && (
                <div className="absolute -top-10 bg-[#6699FF] text-center w-full left-1/2 transform -translate-x-1/2 text-sm font-bold text-white p-3 rounded-t-2xl">
                  Most popular
                </div>
              )}
              <div
                onClick={() => setSelected(option.label.toLowerCase())}
                className={`cursor-pointer dark:bg-[#121212] dark:border-[#252525] flex flex-col items-center p-2 border box-shadow w-full lg:w-[160px] lg:h-[138px] ${
                  isSelected
                    ? "border-[#6699FF] border-2 bg-blue-50 dark:bg-[#121212] dark:border-[#252525]"
                    : "border-gray-300"
                } ${
                  isPopular ? "rounded-t-none rounded-b-2xl" : "rounded-2xl"
                }`}
              >
                <div
                  className={`text-lg font-semibold ${
                    isSelected ? "text-[#6699FF]" : "text-black dark:text-white"
                  }`}
                >
                  {option.label}
                </div>
                <div className="text-gray-500 text-[14px] text-center mt-1">
                  {option.description}
                </div>
                <div
                  className={`mt-4 w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                    isSelected ? "border-[#6699FF]" : "border-gray-300"
                  }`}
                >
                  {isSelected && (
                    <div className="w-2.5 h-2.5 rounded-full bg-[#6699FF]"></div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EvaluationStages;
