import React, { useEffect, useState } from "react";
import { getNewChallenge } from "../../../../Redux/Slices/NewChallengeSlices";
import { useDispatch } from "react-redux";

const AccountBalance = () => {
  const dispatch = useDispatch();

  const options = [
    { label: "$2500", isPopular: true },
    { label: "$25,000", isPopular: false },
    { label: "$5,000", isPopular: false },
    { label: "$10,000", isPopular: false },
    { label: "$50,000", isPopular: false },
    { label: "$100,000", isPopular: false },
    { label: "$200,000", isPopular: false },
  ];

  // Ensure a valid default value
  const storedValue = sessionStorage.getItem("account_balance");
  const defaultBalance = options.some((opt) => opt.label === storedValue)
    ? storedValue
    : "$2500";

  const [selected, setSelected] = useState(defaultBalance);

  useEffect(() => {
    sessionStorage.setItem("account_balance", selected);
  }, [selected]);

  useEffect(() => {
    const sanitizedValue = selected.replace(/[$,]/g, "");
    dispatch(getNewChallenge(sanitizedValue));
  }, [selected, dispatch]);

  return (
    <div>
      <div className="text-[#6699FF] font-semibold text-[20px] text-center lg:mt-0 mt-5">
        Account Balance
      </div>
      <div className="text-[16px] text-[#6C6C6C] font-normal text-center">
        $5,000 - $200,000
      </div>

      <div className="flex lg:justify-start lg:flex-row flex-col flex-wrap gap-4 mt-14">
        {options.map((option) => (
          <div key={option.label} className="relative">
            {option.isPopular && (
              <div className="absolute -top-10 bg-[#6699FF] text-center w-full left-1/2 transform -translate-x-1/2 text-sm font-bold text-white p-3 rounded-t-2xl">
                Most popular
              </div>
            )}
            <div
              onClick={() => setSelected(option.label)}
              className={`cursor-pointer dark:bg-[#121212] dark:border-[#252525] flex lg:flex-col gap-2 justify-end flex-row-reverse items-center p-2 border box-shadow lg:w-[150px] ${
                selected === option.label
                  ? "border-[#6699FF] border-2 bg-blue-50"
                  : "border-gray-300"
              } ${
                option.isPopular
                  ? "rounded-t-none rounded-b-2xl"
                  : "rounded-2xl"
              }`}
            >
              <div
                className={`text-[24px] font-semibold ${
                  selected === option.label
                    ? "text-[#6699FF]"
                    : "text-black dark:text-white"
                }`}
              >
                {option.label}
              </div>

              <div
                className={`lg:mt-4 w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                  selected === option.label
                    ? "border-[#6699FF]"
                    : "border-gray-300"
                }`}
              >
                {selected === option.label && (
                  <div className="w-2.5 h-2.5 rounded-full bg-[#6699FF]"></div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountBalance;
