import React, { useState } from "react";
import images from "../../Assets/Assets";
import { useNavigate } from "react-router-dom";
import Logincarousel from "../Logincarousel/Logincarousel";
import { useDispatch, useSelector } from "react-redux";
import { forgetUserPassword } from "../../Redux/Slices/AuthSlices";
import { toast, ToastContainer } from "react-toastify";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) return;

    try {
      await dispatch(forgetUserPassword({ email })).unwrap();
      toast.success("Email has been sent");
    } catch (error) {
      toast.error(error || "Something went wrong");
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={1000} />
      <div className="lg:flex lg:justify-between">
        {/* Left Section */}
        <section className="hidden lg:block lg:w-[843px]">
          <div
            className="w-full h-[100vh] bg-cover bg-center text-white lg:flex flex-col justify-center items-center relative"
            style={{ backgroundImage: `url(${images.background})` }}
          >
            <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
            <div className="absolute top-10 left-10 z-20">
              <img src={images.logo} alt="logo" />
            </div>
            <div className="font-semibold w-[563px] text-center z-20 mt-20">
              <div className="text-[58px] leading-[56px] text-white">
                Manage your Money Anywhere
              </div>
              <div className="text-[#FFFFFF] text-[16px] text-center leading-[24px] mt-5">
                Investing is not just a financial decision, it's a commitment to
                your future prosperity.
              </div>
              <div className="mt-12 w-full overflow-visible">
                <Logincarousel />
              </div>
            </div>
          </div>
        </section>

        {/* Right Section */}
        <section className="w-full lg:w-1/2 flex justify-center items-center">
          <div className=" md:w-[500px] w-full mx-4 px-8 py-10">
            <div className="md:hidden pt-5 ">
              <img src={images.logo} alt="logo" />
            </div>

            <div className="lg:text-center text-left">
              <h2 className="text-[34px] font-semibold text-gray-900 dark:text-white">
                Forgot Password
              </h2>
            </div>

            <div className="text-[#6C6C6C] dark:text-gray-400 mt-4 lg:text-center text-left">
              Enter your email and we'll send you instructions to reset your
              password
            </div>

            <form className="mt-5 flex flex-col gap-4" onSubmit={handleSubmit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full h-[50px] dark:bg-[#2A2A2A] dark:border-[#424346] border border-gray-300 rounded-lg px-4 outline-none text-gray-900 dark:text-white"
                required
              />
              <button
                type="submit"
                className="w-full bg-[#6699FF] text-white  h-[44px] rounded-lg font-semibold "
                disabled={loading}
              >
                {loading ? "Sending..." : "Send"}
              </button>
            </form>

            <div className="text-center mt-4">
              <button
                onClick={() => navigate("/login")}
                className="text-[#6D788D]  h-[44px] dark:text-gray-400 font-semibold hover:underline"
              >
                Back To Login
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ForgetPassword;
