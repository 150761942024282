import {
  Account,
  Affiliate,
  Competition,
  Education,
  Home,
  Leaderboard,
  Mastery,
  Star,
  Support,
  Tools,
} from "../../Assets/Sidebar/SvgComponents";
export const Menus = [
  { icon: <Home />, title: "Home", path: "/home" },
  {
    heading: "Challenges & Achievements",
    icon: <Star />,
    title: "Challenge",
    submenu: true,
    submenuItems: [
      { title: "New Challenges", path: "/challenge/new-challenge" },
      { title: "My Challenge", path: "/challenge/my-challenge" },
    ],
    // path: "/challenge",
  },
  {
    icon: <Competition />,
    title: "Competitions",
    path: "/competitions",
  },
  {
    icon: <Leaderboard />,
    title: "Leaderboard",
    path: "/leaderboard",
  },
  {
    heading: "Tools & Education",
    icon: <Tools />,
    title: "Tools",
    submenu: true,
    submenuItems: [
      { title: "Advanced Chart", path: "/tools/advanced-chart" },
      { title: "Economic Calender", path: "/tools/economic-calendar" },
      { title: "Calculators" },
      { title: "Downloads", path: "/tools/downloads" },
    ],
    // path: "/tools",
  },
  {
    icon: <Education />,
    title: "Education",
    submenu: true,
    submenuItems: [
      { title: "Trading Courses", path: "/education/trading-courses" },
      {
        title: "Propmodel Tutorials",
        path: "/education/propmodel-tutorials",
      },
    ],
    // path: "/education",
  },
  {
    icon: <Mastery />,
    title: "Mastery",
    submenu: true,
    submenuItems: [
      { title: "Achievements", path: "/mastery/achievements" },
      { title: "Certificates", path: "/mastery/certificates" },
    ],
    // path: "/mastery",
  },
  {
    heading: "My Account",
    icon: <Affiliate />,
    title: "Affiliate",
    path: "/affiliate",
  },
  {
    icon: <Account />,
    title: "Account",
    submenu: true,
    submenuItems: [
      { title: "Profile", path: "/account/profile" },
      { title: "Security", path: "/account/security" },
      { title: "Billing", path: "/account/billing" },
      { title: "Certificates", path: "" },
    ],
    // path: "/account",
  },
  {
    icon: <Support />,
    title: "Support",
  },
];
