import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../Redux/Slices/AuthSlices";
import Loader from "../../../Components/Loader/Loader";

const Profile = () => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.auth);

  // Fetch user data
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  // Initialize state and update when user data is fetched
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    timezone: "",
    postalCode: "",
    state: "",
    country: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
        phone: user.phone || "",
        address: user.address || "",
        city: user.city || "",
        timezone: user.timezone || "",
        postalCode: user.zip || "",
        state: user.state || "",
        country: user.country || "",
      });
    }
  }, [user]);

  const [isEditing, setIsEditing] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [generatedCode] = useState("123456");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    setIsVerifying(true);
  };

  const handleCancel = () => {
    setFormData({
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      address: user?.address || "",
      city: user?.city || "",
      timezone: user?.timezone || "",
      postalCode: user?.zip || "",
      state: user?.state || "",
      country: user?.country || "",
    });
    setIsEditing(false);
  };

  const handleSmsVerification = () => {
    if (smsCode === generatedCode) {
      setIsVerifying(false);
      setIsEditing(false);
      console.log("Changes saved:", formData);
    } else {
      alert("Invalid SMS code. Please try again.");
    }
  };

  const handleSmsCancel = () => {
    setIsVerifying(false);
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (error) return <p className="text-red-500">Error: {error}</p>;

  return (
    <div className="bg-background border-childBorder rounded-2xl lg:w-[768px] p-5">
      <div className="font-semibold text-[20px]">Profile Information</div>

      <form>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-5">
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="First Name"
            disabled={!isEditing}
          />
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Last Name"
            disabled={!isEditing}
          />
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            disabled
          />
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
            disabled={!isEditing}
          />
        </div>

        <hr className="my-5" />

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="Address"
            disabled={!isEditing}
          />
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="City"
            disabled={!isEditing}
          />
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="timezone"
            value={formData.timezone}
            onChange={handleChange}
            placeholder="Timezone"
            disabled={!isEditing}
          />
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            placeholder="Postal Code"
            disabled={!isEditing}
          />
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="state"
            value={formData.state}
            onChange={handleChange}
            placeholder="State"
            disabled={!isEditing}
          />
          <input
            className="w-full h-[56px] rounded-xl border pl-2 outline-none"
            name="country"
            value={formData.country}
            onChange={handleChange}
            placeholder="Country"
            disabled={!isEditing}
          />
        </div>

        <hr className="my-5" />

        <div className="flex justify-end gap-3">
          {isEditing ? (
            <>
              <button
                type="button"
                onClick={handleCancel}
                className="bg-[#FAFCFD] border-[#EAEAEC] dark:bg-[#121212] dark:border-[#424346] border w-[122px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSave}
                className="bg-[#6699FF] w-[146px] h-[38px] rounded-lg text-white font-semibold"
              >
                Save Changes
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={handleEditToggle}
              className="bg-[#6699FF] w-[122px] h-[38px] rounded-lg text-white font-semibold"
            >
              Edit Profile
            </button>
          )}
        </div>
      </form>

      {isVerifying && (
        <div className="fixed inset-0 pt-5 flex lg:justify-center lg:items-center justify-end flex-col bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white dark:bg-[#121212]  p-6 lg:rounded-lg rounded-t-2xl lg:w-[35%] w-full">
            <div className="flex flex-col justify-center items-center">
              <div className="lg:text-[34px] text-[20px] font-semibold ">
                Verify Your SMS Code
              </div>
              <div className="text-[#6C6C6C] text-center mb-4 lg:w-[350px] lg:text-[16px] text-[14px]">
                Enter the verification code sent to your mobile. Didn't get the
                code?{" "}
                <span className="text-[#6699FF] font-semibold"> Resend </span>
              </div>
            </div>

            <input
              type="text"
              className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 mb-4 outline-none text-[#6C6C6C]"
              placeholder="Verification Code"
              value={smsCode}
              onChange={(e) => setSmsCode(e.target.value)}
            />
            <hr className="my-5 dark:border-[#252525]" />
            <div className="flex justify-end gap-5">
              <button
                type="button"
                onClick={handleSmsCancel}
                className="bg-[#FAFCFD] dark:bg-[#1C1C1C] border-childBorder border w-[122px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSmsVerification}
                className="bg-[#6699FF] w-[122px] h-[38px] rounded-lg text-white font-semibold"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
