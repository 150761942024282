import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import images from "../../Assets/Assets";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logincarousel from "../Logincarousel/Logincarousel";
import { useDispatch, useSelector } from "react-redux";
import { resetUserPassword } from "../../Redux/Slices/AuthSlices";
import { toast, ToastContainer } from "react-toastify";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const resetToken = searchParams.get("token");

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Ensure passwords are entered and match
    if (!credentials.new_password || !credentials.confirm_password) {
      return toast.error("Both password fields are required!");
    }
    if (credentials.new_password !== credentials.confirm_password) {
      return toast.error("Passwords do not match!");
    }
    if (!resetToken) {
      return toast.error(
        "Invalid or missing reset token. Please check your link."
      );
    }

    // Prepare payload for API request
    const payload = {
      reset_token: resetToken,
      new_password: credentials.new_password,
      confirm_password: credentials.confirm_password,
    };

    try {
      const response = await dispatch(resetUserPassword(payload)).unwrap();

      if (response?.success) {
        toast.success(
          response.message || "Password reset successful! Please log in."
        );
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        toast.error(
          response.message || "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      console.error("❌ Reset Password Error:", error);
      toast.error(error?.message || "Failed to reset password. Try again.");
    }
  };
  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="lg:flex lg:justify-between">
        {/* Left Section */}
        <section className="hidden lg:block lg:w-[843px]">
          <div
            className="w-full h-[100vh] bg-cover bg-center text-white lg:flex flex-col justify-center items-center relative"
            style={{ backgroundImage: `url(${images.background})` }}
          >
            <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
            <div className="absolute top-10 left-10 z-20">
              <img src={images.logo} alt="logo" />
            </div>
            <div className="font-semibold w-[563px] text-center z-20 mt-20">
              <div className="text-[58px] leading-[56px] text-white">
                Manage your Money Anywhere
              </div>
              <div className="text-[#FFFFFF] text-[16px] text-center leading-[24px] mt-5">
                Investing is not just a financial decision, it's a commitment to
                your future prosperity.
              </div>
              <div className="mt-12 w-full overflow-visible">
                <Logincarousel />
              </div>
            </div>
          </div>
        </section>

        {/* Right Section */}

        <section className="w-full lg:w-1/2 flex justify-center items-center">
          <div className=" md:w-[500px] w-full p-10 ">
            <div className="md:hidden pt-5 ">
              <img src={images.logo} alt="logo" />
            </div>

            <div className="lg:text-center text-left">
              <h2 className="text-[34px] font-semibold text-gray-800 dark:text-white">
                Reset Password
              </h2>
            </div>

            <div className="flex lg:justify-center items-center">
              <div className="text-[#6C6C6C] w-[300px] mt-4 lg:text-center text-left dark:text-gray-400">
                Your new password must be different from previously used
                passwords
              </div>
            </div>

            <form className="mt-5 flex flex-col gap-4" onSubmit={handleSubmit}>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="new_password"
                  placeholder="New Password"
                  value={credentials.new_password}
                  onChange={handleInputChange}
                  className="w-full h-[50px] dark:bg-[#1E1E1E] dark:border-[#424346] border border-gray-300 rounded-lg px-4 outline-none text-gray-900 dark:text-white"
                />
                <div
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500 dark:text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOff /> : <Eye />}
                </div>
              </div>

              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  placeholder="Confirm Password"
                  value={credentials.confirm_password}
                  onChange={handleInputChange}
                  className="w-full h-[50px] dark:bg-[#1E1E1E] dark:border-[#424346] border border-gray-300 rounded-lg px-4 outline-none text-gray-900 dark:text-white"
                />
                <div
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500 dark:text-gray-400"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <EyeOff /> : <Eye />}
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-[#6699FF] text-white h-[44px]  rounded-lg font-semibold transition duration-200"
                disabled={loading}
              >
                {loading ? "Resting..." : "Reset Password"}
              </button>
            </form>

            <div className="text-center mt-4">
              <button
                onClick={() => navigate("/login")}
                className="text-[#6D788D]   h-[44px] font-semibold hover:underline"
              >
                Back To Login
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ResetPassword;
