import React, { useEffect } from "react";
import logo from "../../Assets/login/Logo.svg";

const VerifyEmail = ({ isOpen, onClose, email }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Clean up when the component is unmounted
    };
  }, [isOpen]);
  if (!isOpen) return null;
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      // onClick={onClose}
    >
      <div
        className="bg-white dark:bg-[#121212]  w-[90%] md:w-[500px] p-6 rounded-lg shadow-lg relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center">
          <img src={logo} alt="logo" />
        </div>

        <div className="">
          <h2 className="text-[34px] text-center font-semibold">
            Verify your email
          </h2>
        </div>

        <div className="text-[#6C6C6C] text-center mt-2">
          Account activation link sent to your email address:{" "}
          <span className="font-semibold text-black">{email}</span> please click
          the link inside to continue to your account.
        </div>
        <div className="text-center mt-5">
          Didn't get the email?{" "}
          <span className="text-[#6699FF] cursor-pointer">Resend</span>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
